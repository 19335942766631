import axios from "axios";

const baseApi = process.env.REACT_APP_API_URI;

const axiosInstance = axios.create({
    baseURL: baseApi
});

axiosInstance.interceptors.request.use((config) => {
    const tokenJWT = localStorage.getItem('tokenJWT');
    if (tokenJWT) {
        config.headers.Authorization = `Bearer ${tokenJWT}`;
    }
    return config;
});

const httpRequest = async (method, url, { params, data } = {}) => {
    try {
        const response = await axiosInstance({
            method,
            url,
            params,  
            data,    
        });
        return response;
    } catch (error) {
        console.error('Erro na requisição:', error);
        return document.location.href = '?Instance=RXJyb3JQYXJhbWV0cm9zSW52YWxpZG9z' //ErrorParametrosInvalidos
    }
};

export async function GetUserAcs() {
    let response = await httpRequest('get', `/Acs/Token`)

    return response.data;
}

export async function GetParametrosReuniao(idReuniao, idAtor) {

    let response = await httpRequest('get', `/parametrizacao?idReuniao=${idReuniao}&idAtor=${idAtor}`);

    if (response.data.scucesso === false) {
        return document.location.href = '?Instance=RXJyb3JQYXJhbWV0cm9zSW52YWxpZG9z' //ErrorParametrosInvalidos
    }
    return response.data;
}

export async function PostFeedback(nota, feedback, idReuniao) {
    let data = { nota: nota, feedback: feedback, idReuniao: idReuniao }
    let response = await httpRequest('post', `/Reuniao/avaliacao`, { data });

    return response.data;
}

export async function PostLogReuniao(idReuniao, idAtor, operacao) {
    let data = { operacao: operacao, idAtor: idAtor, idReuniao: idReuniao }
    let response = await httpRequest('post', `/Reuniao/log`, { data });

    return response.data;
}

export async function PutCancelaReuniao(idReuniao) {
    let data = { Cancelada: true }
    let response = await httpRequest('put', `/Agenda/alterar?IdReuniao=` + {idReuniao}, { data });

    return response.data;
}