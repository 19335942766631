import axios from "axios";

const baseApi = process.env.REACT_APP_API_URI;
const urlAuth = process.env.REACT_APP_AUTENTICACAO_URI;
export async function AuthBusster(bussterToken) {

    let response = await axios.post(
        `${baseApi}/api/Autenticacao/gerar-token/busster`,
        { BussterUrl: urlAuth + "tokenP=" + bussterToken }
    ).catch((error) => {
        console.error(error)
        return document.location.href = '?Instance=RXJyb3JQYWdl' //ErrorPage
    });

    if(response.data.sucesso){
        localStorage.setItem('tokenJWT', response.data.resultado.token);
        localStorage.setItem('nome', response.data.resultado.usuario.nome);
        return  document.location.href = '?Instance=Q29tdW5pY2FjYW9QYWdl'; //ComunicacaoPage
    }
    
    
    return document.location.href = '?Instance=RXJyb3JQYWdl'; //ErrorPage
}

export async function AuthMsal(payload) {
    let response = await axios.post(
        `${baseApi}/api/Autenticacao/gerar-token/ad-omint`,
        { tokenId: payload.tokenId, nome: payload.nome, email: payload.email }
    );
    return response.data;
}

export const Logout = () => {
    localStorage.clear();
}
