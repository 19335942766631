import './App.css';
import ComponentSelector from './components/ComponentSelector/ComponentSelector';
import * as authService from "./services/AuthService";
import { encode as base64_encode, decode as base64_decode } from 'base-64';
const paginasQueNaoPrecisamDeAutenticacao = [
  'MsalLoginPage',
  'ErrorPage',
  'ErrorAuthPage',
  'ErrorParametrosInvalidos',
  'FeedbackPage',
  'DesistenciaPage',
  ''
]

const App = (): any => {
  const urlParams = new URLSearchParams(window.location.search);

  let url = window.location.href;

  const instance = urlParams.get('Instance');
  const Render = instance ? base64_decode(instance) : null;

  let bussterToken = "";
  let device = urlParams.get('device')
  if(device){
    localStorage.setItem('device', device)
  }

  if (url.includes('idReuniao') && url.includes('idAtor') && !url.includes('Instance')) {
    const idReuniao = urlParams.get('idReuniao');
    const idAtor = urlParams.get('idAtor');
    if (idReuniao && idAtor) {
      localStorage.setItem('idReuniao', idReuniao);
      localStorage.setItem('idAtor', idAtor);
    }
    if (url.includes('tokenP')) {
      const queryString = url.split('tokenP')[1];
      bussterToken = queryString.replace('=', '');
      const auth = async () => {
        await authService.AuthBusster(bussterToken);
      }
       auth();
    } else{
      document.location.href = '?Instance=TXNhbExvZ2luUGFnZQ==' //MsalLoginPage;
    }
     
  }
  else if (!url.includes('Instance')) {
    document.location.href = '?Instance=RXJyb3JQYXJhbWV0cm9zSW52YWxpZG9z' //ErrorParametrosInvalidos;
  }

  if (!localStorage.getItem("tokenJWT") && Render && !paginasQueNaoPrecisamDeAutenticacao.includes(Render)) {
    document.location.href = '?Instance=RXJyb3JBdXRoUGFnZQ' //ErrorAuthPage;
  }

  

  if (Render) {
    return <ComponentSelector Render={Render} />
  }
}

export default App;
