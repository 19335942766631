
import { AzureCommunicationTokenCredential, CommunicationUserIdentifier } from '@azure/communication-common';
import {
  CallAdapter,
  CallComposite,
  CallCompositeOptions,
  useAzureCommunicationCallAdapter,
  fromFlatCommunicationIdentifier,
  COMPOSITE_LOCALE_PT_BR,
  DEFAULT_COMPOSITE_ICONS,
  CallCompositePage
} from '@azure/communication-react';
import React, { useMemo, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import '../../pages/ComunicacaoPage/ComunicacaoPage.css';
import * as comunicacaoService from "../../services/ComunicacaoService";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: 'flex',
  borderRadius: 4,
  flexDirection: 'column'
};

export type ChamadaProps = {
  userIdentifier: string;
  token: string;
  link: string;
  displayName: string;
  avatarInitials?: string;
  formFactor?: 'desktop' | 'mobile';
  options: CallCompositeOptions;
  lobby?: LobbyProps;
};

export type LobbyProps = {
  mensagemLobby?: string;
  imagemLobby?: string;
};

export const ChamadaContainer = (props: ChamadaProps): JSX.Element => {
  let locale = COMPOSITE_LOCALE_PT_BR;
  let icons = DEFAULT_COMPOSITE_ICONS;
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  //Personalização tela de entrada
  if (props.lobby?.mensagemLobby) {
    locale.strings.call.lobbyScreenWaitingToBeAdmittedTitle = props.lobby?.mensagemLobby;
    locale.strings.call.lobbyScreenConnectingToCallTitle = props.lobby?.mensagemLobby;
  }


  if (props.lobby?.imagemLobby) {
    icons.LobbyScreenConnectingToCall = <img alt="" src={`data:image/jpeg;base64,${props.lobby?.imagemLobby}`} />
    icons.LobbyScreenWaitingToBeAdmitted = <img alt="" src={`data:image/jpeg;base64,${props.lobby?.imagemLobby}`} />

  }

  const userId = useMemo(
    () => fromFlatCommunicationIdentifier(props.userIdentifier) as CommunicationUserIdentifier,
    [props.userIdentifier]
  );

  const credential = useMemo(() => new AzureCommunicationTokenCredential(props.token), [props.token]);
  const locator = useMemo(
    () => (isTeamsMeetingLink(props.link) ? { meetingLink: props.link } : { groupId: props.link }),
    [props.link]
  );
  const adapter = useAzureCommunicationCallAdapter(
    {
      userId: userId,
      displayName: props.displayName,
      credential,
      locator,
    },
    undefined,
    async (adapter: CallAdapter): Promise<void> => {
      await adapter.leaveCall().catch((e) => {
        console.error('Falha ao carregar chamada.', e);
      });
    }
  );

  const handleClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, reason: any) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleCancelarChamada = () => {
    setOpen(false);
    comunicacaoService.PostLogReuniao(localStorage.getItem('idReuniao') as string, localStorage.getItem('idAtor') as string, 'D');
    comunicacaoService.PutCancelaReuniao(localStorage.getItem('idReuniao') as string);
    window.parent.postMessage("call:ended", "*");
    localStorage.setItem("exibePesquisaSatisfacao", "false");
    adapter?.leaveCall().catch((e) => {
      console.error(e);
    });
  }

  const handleSair = () => {
    setOpen(false);
    comunicacaoService.PostLogReuniao(localStorage.getItem('idReuniao') as string, localStorage.getItem('idAtor') as string, 'S');
    window.parent.postMessage("call:ended", "*");
    localStorage.setItem("exibePesquisaSatisfacao", "false");
    adapter?.leaveCall().catch((e) => {
      console.error(e);
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };


  if (adapter) {
    adapter.on('callEnded', (event) => {
      comunicacaoService.PostLogReuniao(localStorage.getItem('idReuniao') as string, localStorage.getItem('idAtor') as string, 'S');
      window.parent.postMessage("call:ended", "*");
      return window.location.href = '?Instance=RmVlZGJhY2tQYWdl'; //FeedbackPage
    })

    adapter.on("participantsJoined", (event) => { setOpen(false); });

    adapter.onStateChange((state) => {
      if (state.page === "lobby" as CallCompositePage && (props.lobby?.mensagemLobby || props.lobby?.imagemLobby)) {
        setOpen(true);
      }
      if (state.call && Array.isArray(state.call?.remoteParticipants) && state.call.remoteParticipants.length > 1) {
        setOpen(false);
      }
    })
  }

  return (
    <div style={{ height: '100dvh', width: '100vw' }}>
      {adapter && (

        <CallComposite
          adapter={adapter}
          locale={locale}
          formFactor={props?.formFactor}
          options={props?.options}

        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...modalStyle, width: 300 }}>
          <img className="image-start" alt="" src={`data:image/jpeg;base64,${props.lobby?.imagemLobby}`} />
          <div className="modal-message">
            {props.lobby?.mensagemLobby}
          </div>
          <div className="modal-buttons" >
            <Button onClick={handleSair} style={{
              textTransform: 'capitalize', fontWeight: 'bold', minWidth: 130, backgroundColor: "#000080", color: 'white'
            }} variant="contained" disableRipple>Sair</Button>
            <Button onClick={handleOpenDialog} style={{ textTransform: 'capitalize', minWidth: 130 }} color="error" variant="outlined" disableRipple>Cancelar chamada</Button>
          </div>

        </Box>

      </Modal>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Cancelamento da chamada
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja cancelar a chamada? Não será possível entrar novamente.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog} variant="outlined" style={{ textTransform: 'capitalize', fontWeight: 'bold', minWidth: 130, backgroundColor: "#000080", color: 'white' }}>
            Voltar
          </Button>
          <Button onClick={handleCancelarChamada} variant="outlined" color="error">Sim, quero cancelar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const isTeamsMeetingLink = (link: string): boolean => link.startsWith('https://teams.microsoft.com/l/meetup-join');
