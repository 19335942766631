
import { useState } from "react";
import { Rating } from "@fluentui/react-components";
import { PrimaryButton } from '@fluentui/react/lib/Button';
import './FeedbackPage.css';
import LogoImage from "../../assets/logo-white.png";
import * as comunicacaoService from "../../services/ComunicacaoService";
import * as authService from "../../services/AuthService";

export const FeedbackPage = (): JSX.Element => {
  const [nota, setNota] = useState(3);
  const [feedback, setFeedback] = useState('');
  const [submitState, setSubmitState] = useState(false);

  const date = new Date();
  async function submitFeedback() {
    await comunicacaoService.PostFeedback(nota, feedback, localStorage.getItem("idReuniao"));
    setSubmitState(true);
  }

  if (localStorage.getItem("exibePesquisaSatisfacao") === "false") {
    authService.Logout();

    return (
      <div className="feedback-container">
      <header className="feedback-header">
        <img src={LogoImage} alt="Logo" className="feedback-logo" />
      </header>
      <div className="feedback-main">
        <div className="row">
          <h3 className="page-title">A Omint agradece sua participação!</h3>

        </div>
        <div className="row">
          <label>
            A página pode ser fechada.
          </label>
        </div>
      </div>
      <footer className="feedback-footer">
        <p>© {date.getFullYear()} - Omint Saúde e Seguros. Todos os direitos reservados.</p>
      </footer>
    </div>)
  }

  if (submitState) {
    authService.Logout();
    localStorage.removeItem("exibePesquisaSatisfacao");

    return (

      <div className="feedback-container">
        <header className="feedback-header">
          <img src={LogoImage} alt="Logo" className="feedback-logo" />
        </header>
        <div className="feedback-main">
          <div className="row">
            <h3 className="page-title">Obrigado por nos ajudar a melhorar!</h3>

          </div>
          <div className="row">
            <label>
              A página pode ser fechada.
            </label>
          </div>
        </div>
        <footer className="feedback-footer">
          <p>© {date.getFullYear()} - Omint Saúde e Seguros. Todos os direitos reservados.</p>
        </footer>
      </div>)
  }

  return (
    <div className="feedback-container">
      <header className="feedback-header">
        <img src={LogoImage} alt="Logo" className="feedback-logo" />
      </header>
      <div className="feedback-main">
        <div className="row">
          <h3 className="page-title">A Omint gostaria de saber como foi a sua experiência!</h3>

        </div>
        <div className="row">
          <label>
            Dê uma nota de 1 a 5 para a reunião
          </label>
          <Rating color="marigold" size="extra-large" value={nota} onChange={(_, data) => setNota(data.value)} />

        </div>
        <div className="row">
          <textarea className="feedback" placeholder="Deixe uma sugestão, crítica ou elogio para a Omint, contamos com sua colaboração para melhorar cada vez mais!" value={feedback} onChange={text => setFeedback(text.target.value)} />
        </div>

        <div className="row">
          <PrimaryButton type="submit" text="Enviar" onClick={submitFeedback} className="button-submit" />
        </div>
      </div>
      <footer className="feedback-footer">
        <p>© {date.getFullYear()} - Omint Saúde e Seguros. Todos os direitos reservados.</p>
      </footer>
    </div>
  );
};
