import * as cookieService from "./CookieService";
import * as authService from "./AuthService";


export async function MsalService(msalInstance) {
    if (!msalInstance) return;

    // const accounts = msalInstance.getAllAccounts();

    // if (accounts.length > 0) {
    //     //recuperar login cache

    //     const account = accounts[0];
    //     msalInstance.setActiveAccount(account);

    //     // Extrair o valor exp do objeto adAzureObject
    //     const expValue = accounts[0].idTokenClaims.exp;

    //     // Converter o valor exp para uma data em JavaScript (em milissegundos)
    //     const expDate = new Date(expValue * 1000);

    //     // Obter a data atual
    //     const currentDate = new Date();

    //     if (currentDate > expDate) {
    //         //login expirado

    //         const loginRequest = {
    //             scopes: ["User.Read", "email", "offline_access", "openid", "profile"]
    //         };

    //         const response = await msalInstance.loginPopup(loginRequest);

    //         if (response) {
    //             const payload = {
    //                 tokenId: response.account.idToken,
    //                 nome: response.account.name,
    //                 email: response.account.username,
    //             };

    //             const responseAuth = await authService.AuthMsal(payload);
    //             if (responseAuth.sucesso) {
    //                 cookieService.inserirCookie('tokenId', payload.tokenId);
    //                 localStorage.setItem('nome', responseAuth.resultado.usuario.nome );
    //                 localStorage.setItem('tokenJWT', response.token);
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         }
    //     } else {


    //         const payload = {
    //             tokenId: accounts[0].idToken,
    //             nome: accounts[0].name,
    //             email: accounts[0].username,
    //         };



    //         const responseAuth = await authService.AuthMsal(payload);

    //         if (responseAuth.sucesso) {
    //             cookieService.inserirCookie('tokenId', payload.tokenId);
    //             localStorage.setItem('nome', responseAuth.resultado.usuario.nome );
    //             localStorage.setItem('tokenJWT', responseAuth.resultado.token);
    //             return true;
    //         } else {
    //             return false;
    //         }
    //     }
    // } else {
    //novo login

    const loginRequest = {
        scopes: ["User.Read", "email", "offline_access", "openid", "profile"]
    };
    const response = await msalInstance.loginPopup(loginRequest);

    if (response) {

        const payload = {
            tokenId: response.account.idToken,
            nome: response.account.name,
            email: response.account.username,
        };



        const responseAuth = await authService.AuthMsal(payload);
        if (responseAuth.sucesso) {
            cookieService.inserirCookie('tokenId', payload.tokenId);
            localStorage.setItem('nome', responseAuth.resultado.usuario.nome);
            localStorage.setItem('tokenJWT', responseAuth.resultado.token);
            return true;
        } else {
            return false;
        }
    }
    //}
}



