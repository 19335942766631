export const retornarCookie = (keyCookie) => {
    const name = keyCookie + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
        c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
        return decodeURI(c.substring(name.length, c.length));
        }
    }
    return "";
}

export const  inserirCookie = (keyCookie, valor, expiraEm) => {
    if (expiraEm) {
        const expires = "expires=" + expiraEm;
        document.cookie =
        keyCookie + "=" + encodeURI(valor) + ";" + expires + ";path=/";
    } else {
        document.cookie = keyCookie + "=" + encodeURI(valor) + ";";
    }
}

export const  removerCookie = (keyCookie) => {
    document.cookie = keyCookie + "=null;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
}