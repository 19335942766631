import * as authService from "../../services/AuthService";
import { useState } from 'react';
import LogoImage from "../../assets/logo-white.png";
import "./ErrorPage.css";


export const ErrorPage = (): JSX.Element => {

    return (
        <div className="container-error">
          <img className="autenticacao-logo" src={LogoImage} alt="Logo omint"/>
          <div className="autenticacao-mensagem">Erro ao Autenticar, tente novamente!</div>
        </div>)

}

export const ErrorAuthPage = (): JSX.Element => {

  return (
      <div className="container-error">
        <img className="autenticacao-logo" src={LogoImage} alt="Logo omint"/>
        <div className="autenticacao-mensagem">Usuário não está logado, impossível entrar na chamada!</div>
      </div>)

}

export const ErrorParametrosInvalidos = (): JSX.Element => {

  return (
      <div className="container-error">
        <img className="autenticacao-logo" src={LogoImage} alt="Logo omint"/>
        <div className="autenticacao-mensagem">Parâmetros inválidos, não foi possível acessar a chamada!</div>
      </div>)

}