// React
import React from 'react';

// Componentes
import { FeedbackPage } from '../../pages/FeedbackPage/FeedbackPage';
import { ComunicacaoPage } from '../../pages/ComunicacaoPage/ComunicacaoPage';
import { MsalLoginPage } from '../../pages/MsalLoginPage/MsalLoginPage';
import { ErrorPage, ErrorAuthPage, ErrorParametrosInvalidos } from '../../pages/ErrorPage/ErrorPage';
import { DesistenciaPage } from '../../pages/DesistenciaPage/DesistenciaPage';
const componentMapping = {

  FeedbackPage, // RmVlZGJhY2tQYWdl
  ComunicacaoPage, //Q29tdW5pY2FjYW9QYWdl
  MsalLoginPage, //TXNhbExvZ2luUGFnZQ==
  ErrorPage, //RXJyb3JQYWdl
  ErrorAuthPage, //RXJyb3JBdXRoUGFnZQ
  ErrorParametrosInvalidos, //RXJyb3JQYXJhbWV0cm9zSW52YWxpZG9z
  DesistenciaPage //RGVzaXN0ZW5jaWFQYWdl
};

const ComponentRender = ({ Render }) => {
  const Component = componentMapping[Render];
  return (
        <Component key={Render} />
  );
};



export default ComponentRender;
