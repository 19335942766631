import React, { useEffect, useState } from 'react';
import { ChamadaContainer } from '../../components/Teams/ChamadaSemChat'
import { ChamadaComChatContainer } from '../../components/Teams/ChamadaComChat';
import { Spinner } from '@fluentui/react';
import { CustomCallControlButtonCallback} from '@azure/communication-react';
import './ComunicacaoPage.css';
import * as comunicacaoService from "../../services/ComunicacaoService"

export interface ComunicacaoProps {
}

export type ParamSistema = {
    exibeChat: boolean,
    permiteCompartilharTela: boolean,
    permiteCamera: boolean,
    exibePesquisaSatisfacao: boolean,
    exibeParticipantes: boolean, 
    mensagemLobby: string,
    base64Lobby: string,
    base64Logo: string,
    base64Background: string,
    minutosAlertaChamada: 0,
    mensagemAlerta: string,
    titulo: string,
    descricao: string,
    link: string,
    mensagemDesistencia: string
};

export type UserAcs = {
    userId: string,
    token: string
};

export const ComunicacaoPage = (): JSX.Element => {
    const displayName = localStorage.getItem('nome') as string;
    const [urlBackground, setUrlBackground] = useState('');
    const [urlLogo, setUrlLogo] = useState('');

    const [endpointUrl, setEndpointUrl] = useState(process.env.REACT_APP_ENDPOINT_ACS ?? '');
    const startNow = React.useMemo(() => Date.now(), []);
    const [now, setNow] = React.useState(startNow);

    const secondsPassed = ~~((now - startNow) / 1000);

    const startTime = 60 * 0.1;
    const timeLeft = startTime + (secondsPassed);

    const minuteCount = ~~(timeLeft / 60);
    const secondsCount = timeLeft % 60;
    const cronometro = `${minuteCount}:${secondsCount.toLocaleString("en-US", {
        minimumIntegerDigits: 2
    })}`;

    const cronometroForInjection: CustomCallControlButtonCallback[] = [
        () => ({
            placement: 'primary',
            disabled: true,
            strings: {
                label: cronometro
            }
        }),
    ];

    const [loadingState, setLoadingState] = useState('not_loaded');

    const [params, setParamsState] = useState({
        exibeChat: false,
        permiteCompartilharTela: false,
        permiteCamera: false,
        exibePesquisaSatisfacao: false,
        exibeParticipantes:false,
        mensagemLobby: "",
        base64Lobby: "",
        base64Logo: "",
        base64Background: "",
        minutosAlertaChamada: 0,
        mensagemAlerta: "",
        titulo: "",
        descricao: "",
        link: '',
        mensagemDesistencia: ""
    } as ParamSistema);

    const [userAcs, setUserAcsState] = useState({ userId: "", token: "" } as UserAcs);

    useEffect(() => {
        const init = async () => {

          
            await comunicacaoService.PostLogReuniao(localStorage.getItem('idReuniao') as string, localStorage.getItem('idAtor') as string, 'I');
            let parametros = await comunicacaoService.GetParametrosReuniao(localStorage.getItem('idReuniao') as string, localStorage.getItem('idAtor') as string);

            if (parametros.resultado.desistencia) {
                return window.location.href = "/?Instance=RGVzaXN0ZW5jaWFQYWdl"; //DesistenciaPage
            }
            setParamsState({
                exibeChat: parametros.resultado.exibeChat,
                permiteCompartilharTela: parametros.resultado.permiteCompartilharTela,
                permiteCamera: parametros.resultado.permiteCamera,
                exibePesquisaSatisfacao: parametros.resultado.exibePesquisaSatisfacao,
                exibeParticipantes: parametros.resultado.exibeParticipantes,
                mensagemLobby: parametros.resultado.mensagemLobby,
                base64Lobby: parametros.resultado.base64Lobby,
                base64Logo: parametros.resultado.base64Logo,
                base64Background: parametros.resultado.base64Background,
                minutosAlertaChamada: parametros.resultado.minutosAlertaChamada,
                mensagemAlerta: parametros.resultado.mensagemAlerta,
                titulo: parametros.resultado.titulo,
                descricao: parametros.resultado.descricao,
                link: parametros.resultado.link,
                mensagemDesistencia: parametros.resultado.mensagemDesistencia
            });

            let user = await comunicacaoService.GetUserAcs();
            setUserAcsState({ userId: user.resultado.userId, token: user.resultado.token });
            localStorage.setItem("exibePesquisaSatisfacao", parametros.resultado.exibePesquisaSatisfacao.toString());
            setLoadingState('loaded');
            const timer = setInterval(() => setNow(Date.now()), 1000);
            return () => clearInterval(timer);
        }
        init();

    }, []);


    if (params.minutosAlertaChamada !== 0 && minuteCount === params.minutosAlertaChamada && secondsCount === 0) {
        alert(params.mensagemAlerta);
    }

    if (loadingState === "loaded") {
       

        if(params.base64Background && urlBackground === ''){
            let buffer=Uint8Array.from(atob(params.base64Background), c => c.charCodeAt(0));
            let blob= new Blob([buffer], { type: "image/gif" });
            setUrlBackground(URL.createObjectURL(blob));
        }

        
        if(params.base64Logo && urlLogo === ''){
            let buffer=Uint8Array.from(atob(params.base64Logo), c => c.charCodeAt(0));
            let blob= new Blob([buffer], { type: "image/gif" });
            setUrlLogo(URL.createObjectURL(blob));
        }

        const options = {
            callControls:
            {
                screenShareButton: params.permiteCompartilharTela,
                onFetchCustomButtonProps: cronometroForInjection,
                cameraButton: params.permiteCamera,
                peopleButton: params.exibeParticipantes,
                teamsMeetingPhoneCallButton: false,
            },
            surveyOptions: {
                disableSurvey: true
            },
            errorBar: true,
            branding: {
                logo: {
                    url: urlLogo,
                    alt: 'Logo Omint'
                },
                backgroundImage: {
                     url: urlBackground
                     
                },
                
            },
            ParticipantList: params.exibeParticipantes         
            
        };

        if (params.exibeChat) {
            return (
                <div>
                    <div >
                        <ChamadaComChatContainer
                            userIdentifier={userAcs.userId}
                            locator={{ meetingLink: params.link }}
                            token={userAcs.token}
                            endpointUrl={endpointUrl}
                            displayName={displayName}
                            formFactor={localStorage.getItem('device') as 'desktop' | 'mobile'}
                            lobby={{ mensagemLobby: params.mensagemLobby, imagemLobby: params.base64Lobby }}
                            options={options}                        
                        />
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div >
                    <ChamadaContainer
                        userIdentifier={userAcs.userId}
                        token={userAcs.token}
                        link={params.link}
                        displayName={displayName}
                        formFactor={localStorage.getItem('device') as 'desktop' | 'mobile'}
                        lobby={{ mensagemLobby: params.mensagemLobby, imagemLobby: params.base64Lobby }}
                        options={options}
                    />
                </div>
            </div>
        );

    }

    return (<div className="comunicacao-container"> <Spinner label="Inicializando a chamada, aguarde..." /></div>);

}