import React, { useEffect, useState } from "react";
import { PublicClientApplication, PopupRequest } from "@azure/msal-browser";
import ReactLoading from "react-loading";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import Typography from "@mui/material/Typography";
import LogoImage from "../../assets/logo-omint.png";
import { MsalService } from "../../services/MsalService";
import './MsalLoginPage.css';

export type msalConfig = {
    auth: {
        clientId: string,
        authority: string,
        redirectUri: string,
    }
};

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  export const MsalLoginPage = (): JSX.Element =>  {

    const [clientId, setClientId] = useState(process.env.REACT_APP_AD_LOGIN_CLIENT_ID ?? '');
    const [tenantId, setTenantId] = useState(process.env.REACT_APP_AD_LOGIN_TENANT_ID ?? '');
    const [redirect, setRedirect] = useState(process.env.REACT_APP_REDIRECT_URI ?? '');

    const microsoftIconStyle = {
        verticalAlign: "middle",
        marginBottom: "5px",
        width: "20px",
        height: "40px",
        marginLeft: "2px",
    };
    
    const [msalConfig, setMsalConfig] = useState({
        auth: {
            clientId: clientId,
            authority: `https://login.microsoftonline.com/${tenantId}`,
            redirectUri: redirect,
        }
    } as msalConfig);

    const [msalInstance, setMsalInstance] = useState(new PublicClientApplication(msalConfig));
    const [loading, setLoading] = useState(false);
    const [modalErro, setModalErro] = useState(false);

    useEffect(() => {
        const initializeMsal = async () => {
            const msalInstance = new PublicClientApplication(msalConfig);
            await msalInstance.initialize();
            setMsalInstance(msalInstance);
        };

        initializeMsal();
    }, []);

    const handleCloseModalErro = () => {
        setModalErro(false);
    };

    const handleLogin = async () => {
        if (!msalInstance) return;

        setLoading(true);
        const loginResult = await MsalService(msalInstance);

        if (loginResult) {
            document.location.href = redirect;
        } else {
            setLoading(false);
            setModalErro(true);
        }
    }

    return (
        <>
            <div className="container-login">
                <br />
                <img src={LogoImage} className="page-header-logo"  alt="LogoOmint"/>
                <br />
                <button
                    style={{ textTransform: "none" }}
                    className="btn btn-blue"
                    onClick={handleLogin}
                >
                    Login com Microsoft <MicrosoftIcon style={microsoftIconStyle} />
                </button>
            </div>
            <Modal open={loading}>
                <Box sx={style}>
                    <div className="loading">
                        <ReactLoading type="spin" color="#102a46" />
                    </div>
                </Box>
            </Modal>
            <Modal
                open={modalErro}
                onClose={handleCloseModalErro}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Não autorizado!
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Seu usuário não pertence a organização.
                    </Typography>
                    <div className="buttonsModalM">
                        <button
                            className="btn exclusao-btn-white"
                            onClick={handleCloseModalErro}
                        >
                            Ok
                        </button>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

