import React, { useEffect, useState } from 'react';import './DesistenciaPage.css';
import LogoImage from "../../assets/logo-white.png";
import * as comunicacaoService from "../../services/ComunicacaoService"
import * as authService from "../../services/AuthService";
export const DesistenciaPage = (): JSX.Element => {

    const date = new Date();
    const [mensagemAviso, setMensagemAviso] = useState("A reunião foi cancelada pois houve desistência de um dos participantes. Desculpe o transtorno.");
    useEffect(() => {
        const init = async () => {
            let parametros = await comunicacaoService.GetParametrosReuniao(localStorage.getItem('idReuniao') as string, localStorage.getItem('idAtor') as string);
            if(parametros.resultado.mensagemDesistencia){
                setMensagemAviso(parametros.resultado.mensagemDesistencia);
            }

            authService.Logout();
        }
        init();

    }, []);

    return (
        <div className="aviso-container">
            <header className="aviso-header">
                <img src={LogoImage} alt="Logo" className="aviso-logo" />
            </header>
            <main className="aviso-main">
                <h1>Videoconferência cancelada!</h1>
                <p>{mensagemAviso}</p>
            </main>
            <footer className="aviso-footer">
                <p>© {date.getFullYear()}- Omint Saúde e Seguros. Todos os direitos reservados.</p>
            </footer>
        </div>
    );
};
